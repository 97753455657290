import React from "react";
import style from "./Preloader.module.scss";
import omm from "../../assets/imgs/omm.png";

const Preloader = () => {
  return (
    <>
      <div className={style.preloader}>
        <img src={omm} alt="" />
        {/* <span>загрузка</span> */}
        <div className={style.text}>
          <span>з</span>
          <span>а</span>
          <span>г</span>
          <span>р</span>
          <span>у</span>
          <span>з</span>
          <span>к</span>
          <span>а</span>
        </div>
      </div>
    </>
  );
};

export default Preloader;
