import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Preloader from "./components/Preloader/Preloader.jsx";
// import Homepage from "./pages/Homepage/Homepage";
// import About from "./pages/About/About";
// import Portfolio from "./pages/Portfolio/Portfolio";
// import Contacts from "./pages/Contacts/Contacts";
// import Page404 from "./pages/Page404/Page404";
import ParticlesBG from "./components/ParticlesBG/ParticlesBG";
// import BriefForm from "./components/BriefForm/BriefForm";
import style from "./App.module.scss";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import CookiesDisclaimer from "./components/CookiesDisclaimer/CookiesDisclaimer";

const Homepage = lazy(() => {
  return Promise.all([
    import("./pages/Homepage/Homepage"),
    new Promise((resolve) => setTimeout(resolve, 1500)),
  ]).then(([moduleExports]) => moduleExports);
});

const About = lazy(() => {
  return Promise.all([
    import("./pages/About/About"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});

const Portfolio = lazy(() => {
  return Promise.all([
    import("./pages/Portfolio/Portfolio"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});

const Contacts = lazy(() => {
  return Promise.all([
    import("./pages/Contacts/Contacts"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});

const Page404 = lazy(() => {
  return Promise.all([
    import("./pages/Page404/Page404"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});

const Brief = lazy(() => {
  return Promise.all([
    import("./pages/Brief/Brief"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});

const Privacy = lazy(() => {
  return Promise.all([
    import("./pages/Privacy/Privacy"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});

function App() {
  return (
    <>
      <div className={style.appWrapper}>
        <div className={style.app}>
          <header className={style.header}>
            <Header />
          </header>
          <Suspense fallback={<Preloader />}>
            <div className={style.mainContentWrapper}>
              <ParticlesBG />
              <div className={style.mainContent}>
                <Routes preserverScrollPosition={true}>
                  <Route index path="/" element={<Homepage />} />
                  <Route path="about" element={<About />} />
                  <Route path="portfolio" element={<Portfolio />} />
                  <Route path="contacts" element={<Contacts />} />

                  <Route path="*" element={<Page404 />} />

                  <Route path="brief" element={<Brief />} />
                  <Route path="privacy" element={<Privacy />} />
                </Routes>
              </div>
            </div>
          </Suspense>
          <footer className={style.footer}>
            <Footer />
          </footer>
          <NotificationContainer />
          <CookiesDisclaimer />
        </div>
      </div>
    </>
  );
}

export default App;
