import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import style from "./Header.module.scss";
import logo from "../../assets/imgs/logoW.svg";
import { motion } from "framer-motion";

const Header = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <>
      <div className={style.headerWrapper}>
        <div className={style.header}>
          <div className={style.logo}>
            <NavLink
              to="/"
              onClick={() => {
                setIsNavExpanded(false);
              }}
            >
              <img src={logo} alt="" />
              <span>Mithra</span>
            </NavLink>
          </div>

          <button
            className={style.hamburger}
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          <div
            className={
              isNavExpanded ? `${style.menu} ${style.expanded}` : style.menu
            }
          >
            <ul className={style.nav}>
              <li className={style.navItem}>
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    isActive ? `${style.active}` : null
                  }
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  <span>Обо мне</span>
                </NavLink>
              </li>
              <li className={style.navItem}>
                <NavLink
                  to="/portfolio"
                  className={({ isActive }) =>
                    isActive ? `${style.active}` : null
                  }
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  <span>Портфолио</span>
                </NavLink>
              </li>
              <li className={style.navItem}>
                <NavLink
                  to="/contacts"
                  className={({ isActive }) =>
                    isActive ? `${style.active}` : null
                  }
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  <span>Контакты</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
